// Desk.js
import React, { useEffect, useRef, useState } from 'react';
import Glide from '@glidejs/glide';
import { useDeskRef } from './DeskRefContext'; // Import the context hook
import Slider from './Slider';

function Desk() {
  const deskElementRef = useRef(null);
  const glideRef = useRef(null);
  const deskRef = useDeskRef(); // Use the context hook to access deskRef
  const [type, setType] = useState(false);
  let previousIndex = 0;

  const deskModern = ['desk_modern_1.png', 'desk_modern_2.png'];
  const deskTrading = ['desk_trading_1.png', 'desk_trading_2.png', 'desk_trading_3.png', 'desk_trading_4.png'];

  const [deskImages, setDeskImages] = useState([]);
  // let customDesk = true;
  // let currentSelection = 0;
  const [customDesk, setCustomDesk] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(0);

  useEffect(() => {
    if (!type) {
      setDeskImages(deskModern);
    } else {
      setDeskImages(deskTrading);
    }
  }, [type]);

  const clearDesk = () => {
    setCustomDesk(false);
    setCurrentSelection(0);
  }

  const switchDesk = (index) => {
    // console.log('switchDesk()', index)
    // customDesk = true;
    // currentSelection = index;
    setCustomDesk(true);
    setCurrentSelection(index);
  }

  useEffect(() => {
    if (deskElementRef.current) {
      if (glideRef.current) {
        glideRef.current.destroy();
      }

      glideRef.current = new Glide(deskElementRef.current, {
        type: 'slider',
        perView: 3,
        focusAt: 'center',
        animationDuration: 200,
        rewind: false,
        // gap: 0
      });

      glideRef.current.on('run', (event) => {
        const currentSlideIndex = glideRef.current.index;

        // Check if the index has changed and run your function
        if (currentSlideIndex !== previousIndex) {
          deskRef.current = !deskRef.current; // Update deskRef via context
          setType(deskRef.current);
          clearDesk()
          previousIndex = currentSlideIndex;
        }

      });

      glideRef.current.mount();
    }
  }, [deskRef]);

  return (
    <>
      <div className="glide desks" ref={deskElementRef}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            <li className="glide__slide modern badge_container">
              <img src={`assets/${!type && customDesk ? deskImages[currentSelection] : 'desk_modern.png'}`} alt="Modern" loading="lazy" />
              {!type && <div className="badge_modern">Modern<br /> Trading</div>}
            </li>
            <li className="glide__slide trading badge_container">
              <img src={`assets/${type && customDesk ? deskImages[currentSelection] : 'desk_trading.png'}`} alt="Trading" loading="lazy" />
              {type && <div className="badge_trading">Full<br /> Trading</div>}
            </li>
          </ul>
        </div>
      </div>
      <Slider type={type} clearDesk={clearDesk} switchDesk={switchDesk} />
    </>
  );
}

export default Desk;
