import React, { useState, useEffect, useRef } from 'react';
import Glide from '@glidejs/glide';

function Slider({ type, clearDesk, switchDesk }) {
  const attachmentModern = ['attachment_modern_1.png', 'attachment_modern_2.png'];
  const attachmentTrading = ['attachment_trading_1.png', 'attachment_trading_2.png', 'attachment_trading_3.png', 'attachment_trading_4.png'];

  const [attachmentImages, setAttachmentImages] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemAnimation, setSelectedItemAnimation] = useState(null);
  const deskElementRef = useRef(null);
  const glideRef = useRef(null);

  useEffect(() => {
    if (!type) {
      setAttachmentImages(attachmentModern);
      setSelectedItem(null); // Reset selected item when type changes
      setSelectedItemAnimation(null); // Reset selected item when type changes
    } else {
      setAttachmentImages(attachmentTrading);
      setSelectedItem(null); // Reset selected item when type changes
      setSelectedItemAnimation(null); // Reset selected item when type changes
    }
  }, [type]);

  useEffect(() => {
    if (deskElementRef.current) {
      if (glideRef.current) {
        glideRef.current.destroy();
      }

      glideRef.current = new Glide(deskElementRef.current, {
        type: type ? 'carousel' : 'slider',
        perView: 3,
        focusAt: 'center',
        animationDuration: 300,
        rewind: false,
        // gap: 0
      });

      glideRef.current.on('run.after', (event) => {
        // No need to update deskRef here
      });

      glideRef.current.mount();
    }
  }, [attachmentImages]);

  const handleItemClick = (index) => {
    // console.log(index)
    if (selectedItem !== index) {
      setSelectedItem(index === selectedItem ? null : index);
      setSelectedItemAnimation(index === selectedItem ? null : index);
      clearDesk();
      setTimeout(() => {
        setSelectedItemAnimation(null);
        // set code to replace base desk image here now
        switchDesk(index);
      }, 500);
    } else {
      setSelectedItem(null);
      clearDesk();
    }
  };

  return (
    <div className="glide attachments" ref={deskElementRef}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {attachmentImages.map((image, index) => (
            <li key={index} className={'glide__slide'} onClick={() => handleItemClick(index)}>
              <div className={`item_background ${index === selectedItem ? 'active' : ''}`} style={{ backgroundImage: `url('assets/${image}')` }}>
                <img
                  src={`assets/${image}`}
                  alt={`Attachment ${index}`}
                  loading="lazy"
                  className={`animate_up ${index === selectedItemAnimation ? 'selected' : ''}`}
                />
              </div>
            </li>
          ))}
        </ul>
        <img src="assets/left.svg" alt="Left" className='left' />
        <img src="assets/up.svg" alt="Up" className='up' />
      </div>
    </div>
  );
}

export default Slider;
