// Footer.js
import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <h3>Private and Confidential</h3>
      <p>The content on this page is intended solely for the recipient's use and may contain proprietary information. Any unauthorised disclosure, distribution, or copying of the content is strictly prohibited. If you are not the intended recipient, please exit this page immediately and do not share or reproduce its content.</p>
    </div>
  );
}

export default Footer;
