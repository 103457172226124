// App.js
import React, { useState, useEffect } from 'react';
import Header from './Header';
import Desk from './Desk';
import Footer from './Footer';
import { DeskRefProvider } from './DeskRefContext';

function App() {
  const [password, setPassword] = useState('');
  const storedPassword = localStorage.getItem('appPassword');
  const correctPassword = process.env.REACT_APP_PASSWORD;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [errorWrongPassword, setErrorWrongPassword] = useState('');

  useEffect(() => {
    if (storedPassword === correctPassword) {
      setIsAuthenticated(true);
    }
  }, [storedPassword, correctPassword]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberPasswordChange = (event) => {
    setRememberPassword(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (password === correctPassword) {
      setErrorWrongPassword('');
      if (rememberPassword) {
        localStorage.setItem('appPassword', password);
      }
      setIsAuthenticated(true);
    } else {
      setErrorWrongPassword('Incorrect password, please try again.');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="bg-gray-100 min-h-screen flex items-center justify-center">
        <form className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full" onSubmit={handleSubmit}>
          <img src="assets/sbfi.png" alt="SBFI" className="logo mb-8" />
          <input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={handlePasswordChange}
            className="w-full p-2 rounded border border-gray-300 mb-4"
          />
          <label className="flex items-center text-sm text-gray-700 mb-4">
            <input
              type="checkbox"
              checked={rememberPassword}
              onChange={handleRememberPasswordChange}
              className="mr-2"
            />
            Remember Password
          </label>
          {errorWrongPassword && (
            <label className="flex items-center text-sm text-red-600 mb-4">{errorWrongPassword}</label>
          )}
          <button type="submit" className="w-full bg-black text-white p-2 rounded hover:bg-gray-600">
            Unlock {rememberPassword ? 'on this browser' : 'once'}
          </button>
        </form>
      </div>
    );
  }

  return (
    <DeskRefProvider>
      <>
        <Header />
        <div className="App">
          <div className="hero">
            <h1 className="title">Your Desk, Your Way</h1>
            <h2 className="subtitle">Reimagine your workspace. Create a desk that echoes your needs.</h2>
          </div>
          <div className="content">
            <Desk />
          </div>
        </div>
        <Footer />
      </>
    </DeskRefProvider>
  );
}

export default App;
