// // Header.js
// import React from 'react';

// function Header() {
//   return (
//     <div className="header">
//       <img src="assets/sbfi.svg" alt="SBFI" />
//       <ul>
//         <li><a href="#sbfi">SBFI Financial</a></li>
//         <li><a href="#horizon">Horizon Consoles</a></li>
//         <li><a href="#arc">Arc Workspace</a></li>
//       </ul>
//     </div>
//   );
// }

// export default Header;

// Header.js
import React, { useState } from 'react';

function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div className={`header ${showMobileMenu ? 'mobile-open' : ''}`}>
      <img src="assets/sbfi.png" alt="SBFI" className="logo" />
      <button className="mobile-menu" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
      <ul className={`menu ${showMobileMenu ? 'open' : ''}`}>
        <li><a href="https://www.sbfi-financial.com/" target="_blank">SBFI Financial</a></li>
        <li><a href="https://www.horizonconsoles.com/" target="_blank">Horizon Consoles</a></li>
        <li><a href="https://www.arcworkspace.com/" target="_blank">Arc Workspace</a></li>
      </ul>
    </div>
  );
}

export default Header;
