// DeskRefContext.js
import React, { createContext, useContext, useRef } from 'react';

const DeskRefContext = createContext(null);

export function DeskRefProvider({ children }) {
  const deskRef = useRef(false);
  
  return (
    <DeskRefContext.Provider value={deskRef}>
      {children}
    </DeskRefContext.Provider>
  );
}

export function useDeskRef() {
  return useContext(DeskRefContext);
}
